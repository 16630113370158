.ant-card {
	margin-bottom: 20px !important;
}

.danger {
	color: $danger;
}

.info {
	color: $info;
}

.solved {
	color: $success;
}

.dashboard-view {
	h4 {
		font-size: 2rem;
		color: $text;
	}
}