.sider {
	position: relative;
	z-index: 5;
	border-right: 1px solid #f0f0f0;
	.logo-wrapper {
		padding: 30px;
		border-bottom: 1px solid #f0f0f0;

		img {
			max-width: 100%;
			height: auto;
		}
	}
}

.ant-layout-sider-children {
	display: flex;
	flex-direction: column;

	.ant-menu {
		overflow-y: auto;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 4px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #999;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #888;
		}
	}
}
