header.header-view {
	box-shadow: 0px 5px 5px 0px rgba(black, .02) !important;
	position: relative;
	z-index: 2;
}

.header-inset-view {
	display: flex;
	justify-content: space-between;

	.search-view {
		flex: 1;
		border-right: 1px solid #e5e5e5;
		padding-right: 20px;
		display: flex;
		align-items: center;

		.anticon {
			margin-right: 10px;
		}

		svg {
			font-size: 20px;
			path {
				fill: $c1;
			}
		}
		
		input {
			margin-left: 10px;
		}
	}

	.user-info {
		margin-left: 20px;
		color: $c1;
		display: flex;
		align-items: center;

		.user-avatar {
			margin-left: 10px;
			width: 30px;
			height: 30px;
			border: 2px solid rgba($c2, .3);
			display: flex;
			align-items: center;
			justify-content: center;
			-webkit-border-radius: 40px;
			-moz-border-radius: 40px;
			-ms-border-radius: 40px;
			-o-border-radius: 40px;
			border-radius: 40px;

			svg path {
				fill: $c2;
			}
		}
	}
}

.header-view {
	
}


