.main-wrapper {
	.ant-layout {
		.ant-layout-content {
			min-height: auto;
		}
	}

	height: 100vh;

	.sider {
		height: 100%;

		.ant-menu {
			//height: 100%;
		}
	}

	.page-wrapper {
		padding: 30px;
	}

	.site-layout-background {
		background: white;
		padding: 30px;
	}
}