//
// SWAL
//

.swal2-title {
	color: $c1 !important;
	font-family: $font;
	font-size: 1.5rem !important;
}

.swal2-confirm {
	background: $c2 !important;
}

.swal2-html-container {
	font-family: $font;
	font-size: 1rem;
}

.swal2-confirm,
.swal2-cancel,
.swal2-deny {
	font-family: $font;
	padding: 0.5rem 2rem !important;
}

.swal2-popup {
	box-shadow: rgba(0, 0, 0, 0.016) 0px 2px 0px !important;
}

.swal2-icon {
}

.swal2-cancel {
	box-shadow: rgba(0, 0, 0, 0.016) 0px 2px 0px !important;
	border: 1px solid rgb(217, 217, 217) !important;
	color: $text !important;
	background: white !important;
}

//
// Loading Page
//

.loading-page-view {
	height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg path {
		fill: $c2;
	}
}

//
// Headinhs
//

h1.ant-typography {
	color: $c2 !important;
}

//
// Page title
//

.page-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

//
// users not created
//

.users-not-created {
	background: rgba(#ec3835, 0.05);
	margin-top: 30px;
	padding: 20px;
	border: 1px solid rgba(#ec3835, 0.1);

	h4 {
		color: #ec3835;
		font-size: 1rem;
	}
}

.select-min-option {
	float: right;
	color: #888;
}

code.reveal {
	position: relative;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: black;
	}

	&:hover {
		&:after {
			visibility: hidden;
		}
	}
}

.ticket-conversation {
	height: 100%;
	display: flex;
	flex-direction: column;

	&-messages {
		flex: 1;
		overflow: auto;

		.empty,
		.loading {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}

	&-textarea {
		padding-top: 20px;
		border-top: 1px solid #e5e5e5;
	}
}

.ticket-conversation {
	.ant-comment {
		// .ant-comment-inner {
		// 	padding: 0;
		// }
		margin-bottom: 15px;

		.ant-comment-inner {
			background: #f9f9f9;
			border: 2px solid #f5f5f5;
			padding: 15px;
			border-radius: 3px !important;

			.ant-comment-content-author-name {
				div {
					display: flex;

					.badge {
						margin-left: 10px;
						padding: 1px 5px;
						vertical-align: middle;
						border-radius: 10px;
						position: relative;
						margin-top: -2px;

						&.partner,
						&.partner_admin {
							background: #fb822a;
							color: white;
						}

						&.user {
							background: #41d154;
							color: white;
						}
					}
				}
			}
		}

		&.me {
			.ant-comment-inner {
				background: rgb($c1, 0.05);
				border: 2px solid rgba($c1, 0.15);
				color: $c1;

				.ant-comment-content-author-name {
					div {
						color: $c1 !important;
						font-weight: 500;
					}
				}
			}
		}
	}
}

.ctn-list-wrapper {
	.ctn-list {
		height: 400px;
		overflow: auto;
		border: 1px solid #e5e5e5;
		padding: 20px;
		display: flex;
		flex-direction: column;

		.check-item {
			margin-bottom: 8px;
			border-bottom: 1px solid #e5e5e5;
			padding-bottom: 8px;
		}
	}

	.ctn-list-search {
		background: #f9f9f9;
		padding: 10px;
		border: 1px solid #e5e5e5;
		border-bottom: 0;
	}
}

// V2

.main-wrapper .sider {
	position: fixed;
	left: 10px;
	top: 10px;
	bottom: 10px;
	height: auto;
	border: 1px solid #f9f9f9 !important;
	box-shadow: 0px 10px 28px 0px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
}

.main-wrapper {
	> .ant-layout {
		padding-left: 300px;
	}

	header.header-view {
		border-radius: 10px;
		margin-top: 10px;
		padding-left: 20px;
		margin-right: 10px;
	}

	.page-wrapper {
		> .ant-layout-content {
			border: 1px solid #f1f1f1;
			border-radius: 10px;
		}
	}
}

h3.ant-typography {
	color: $c1 !important;
}

.ant-btn,
.ant-pagination li,
.ant-pagination li button {
	border-radius: 6px !important;
}

.ant-btn-circle {
	border-radius: 50% !important;
}

.ant-radio-group.spaced {
	.ant-radio-wrapper {
		margin-bottom: 10px;
	}
}

.ant-checkbox-group.spaced {
	.ant-checkbox-wrapper {
		margin-bottom: 10px;
	}
}

.rate-item {
	display: flex;
	gap: 2rem;

	.rate-view {
		.anticon-star {
			color: #fec839;
		}
		.outline {
			color: #e5e5e5;
		}
	}

	.rate-description {
		flex: 1;

		em {
			color: $text;
			font-style: normal;
			display: block;
			font-size: 0.8rem;
			margin-top: 10px;
		}
	}
}

.stats-das {
	border: 1px solid #f0f0f0;
	padding: 20px;
	border-radius: 10px;
}

.logo-upload {
	width: 100%;
	aspect-ratio: 1;
	border: 1px dashed #e5e5e5;
	border-radius: 10px;
	display: block;
	margin-bottom: 20px;
	cursor: pointer;

	.ant-upload {
		display: block !important;
		aspect-ratio: 1;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.no-logo {
		width: 100%;
		aspect-ratio: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 3rem;
		color: $text;
		opacity: 0.3;

		&:hover {
			opacity: 1;
		}
	}

	.image {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		position: relative;

		.upload-icon {
			background: $c1;
			position: absolute;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			border-radius: 10px;
			right: 10px;
			bottom: 10px;
		}
	}
}

.text-primary {
	color: $c1;
}

.align-right {
	display: flex;
	justify-content: flex-end;
}

.color-button {
	border: 1px solid #d9d9d9;
	border-radius: 3px;
	display: inline-block;
	padding: 5px;
	padding-right: 10px;
	cursor: pointer;

	&:hover {
		border-color: $c1;
	}

	> i {
		display: inline-block;
		width: 20px;
		height: 20px;
		vertical-align: middle;
		margin-right: 5px;
		margin-right: 5px;
		border-radius: 5px;
	}

	span {
		width: 80px;
		font-family: monospace;
		color: $text;
		display: inline-block;
	}
}

.color-wrapper {
	border: 0 !important;
	box-shadow: none !important;
}

.group-handler {
	display: flex !important;
	margin-bottom: 10px !important;

	button {
		border-radius: 0px 4px 4px 0px !important;
	}

	input {
		border-radius: 4px 0px 0px 4px !important;
	}
}

.vertical-group {
	display: flex !important;
	flex-direction: column !important;
	gap: 5px;
}

.ant-row.ant-form-item.vertical {
	display: flex;
	flex-direction: column;

	.ant-col.ant-form-item-label {
		text-align: left;
	}
}

.password-status-badges {
	.ant-badge {
		.ant-badge-status-dot {
			width: 10px;
			height: 10px;
			cursor: pointer;
		}
	}
}
