body {
	color: $text;
}

.loading-view {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		font-size: 4rem;

		path {
			fill: $c2;
		}
	}
}