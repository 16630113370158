$c1: #0047bb;
$c2: #0047bb;
$text: #888;
$danger: #d34c42;
$info: #005cd0;
$success: #54c761;

$c1_light: #37558e;
$font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
	Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
	"Segoe UI Symbol", "Noto Color Emoji";

@import "../../assets/scss/main.scss";
